.about-us {
    padding: 40px 20px;
    background-color: #f9f9f9;
    text-align: center;
    overflow: hidden; /* To contain floated elements and apply animations */
    position: relative; /* For positioning animations */
}

.about-us h2 {
    font-size: 36px;
    color: #333;
    margin-bottom: 20px;
    animation: fadeInDown 1s ease-out; /* Heading animation */
}

.about-us p {
    font-size: 18px;
    color: #666;
    max-width: 800px;
    margin: 0 auto 20px auto;
    line-height: 1.6;
    animation: fadeInUp 1s ease-out; /* Paragraph animation */
}

.about-content {
    max-width: 800px;
    margin: 0 auto;
    text-align: left;
    animation: fadeInUp 1.2s ease-out;
}

.about-content ul {
    list-style: none; /* Remove default bullets */
    padding-left: 0;
}

.about-content ul li {
    font-size: 18px;
    color: #555;
    line-height: 1.8;
    position: relative;
    padding-left: 30px;
    margin-bottom: 10px;
    animation: fadeInUp 1.4s ease-out; /* List item animation */
}

.about-content ul li:before {
    content: "✓";
    position: absolute;
    left: 0;
    top: 0;
    color: #007bff; /* Bullet color */
    font-weight: bold;
    font-size: 20px;
}

.about-content p {
    text-align: center;
    margin-top: 30px;
}

/* Animations */
@keyframes fadeInDown {
    0% {
        opacity: 0;
        transform: translateY(-20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Mobile Optimization */
@media (max-width: 768px) {
    .about-us {
        padding: 30px 10px;
    }

    .about-us h2 {
        font-size: 28px; /* Smaller font size on mobile */
    }

    .about-content {
        padding: 0 15px; /* Add padding for better readability on mobile */
    }

    .about-content ul li {
        font-size: 16px;
    }
}
