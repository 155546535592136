.footer {
    background: linear-gradient(135deg, #aed7ff, #dbeef6); /* Gradient background */
    color: #333;
    padding: 20px 0;
    text-align: center;
    border-radius: 12px; /* Rounded corners */
    box-shadow: 0 -4px 15px rgba(0, 0, 0, 0.1); /* Light glow effect */
    margin: 20px 10px 0 10px; /* Slight margin for better spacing */
}

.footer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.footer-logo {
    height: 70px; /* Enlarged logo */
    margin-bottom: 15px;
}

.footer p {
    margin: 5px 0;
    font-size: 16px;
}

.social-links {
    display: flex;
    justify-content: center; /* Center the social links horizontally */
    align-items: center; /* Center items vertically */
    gap: 20px;
    margin-top: 15px;
    padding: 0;
    list-style: none; /* Remove bullet points from the list */
}

.social-links li {
    display: flex;
}

.social-link {
    color: #fff;
    font-size: 16px;
    background-color: #007bff;
    padding: 10px 20px;
    border-radius: 25px;
    transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
    box-shadow: 0 4px 10px rgba(0, 123, 255, 0.2);
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center; /* Ensure the text inside the buttons is centered */
}

.social-link.facebook {
    background-color: #1da1f2; /* Facebook color */
}

.social-link.twitter {
    background-color: #39cf6d; /* Twitter color */
}

.social-link.instagram {
    background-color: #e1306c; /* Instagram color */
}

.social-link:hover {
    transform: translateY(-3px);
    box-shadow: 0 8px 20px rgba(0, 123, 255, 0.3);
}

.social-link:active {
    transform: translateY(2px);
    box-shadow: 0 4px 10px rgba(0, 123, 255, 0.2);
}
