.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 40px;
    background: linear-gradient(135deg, #aed7ff, #dbeef6); /* Gradient background */
    color: #333;
    border-radius: 12px; /* Rounded corners */
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); /* Light glow effect */
    margin: 0 10px; /* Slight margin for better spacing */
    position: relative;
    z-index: 1000; /* Ensure the header is above other elements */
}

.header .logo {
    height: 90px; /* Enlarged logo */
}

.nav-links {
    display: flex;
    gap: 30px; /* Increased gap between buttons */
    justify-content: flex-end;
    flex-wrap: nowrap; /* Ensure buttons are on the same line */
    flex-grow: 1; /* Allow nav-links to grow and take up space */
}

.nav-links ul {
    display: flex;
    gap: 20px;
}

.nav-button {
    background-color: #007bff; /* Button background color matching theme */
    color: #fff; /* White text color */
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 20px; /* Rounded button corners */
    text-decoration: none; /* Remove underline from links */
    transition: background-color 0.3s, transform 0.3s; /* Smooth hover effects */
    box-shadow: 0 4px 10px rgba(0, 123, 255, 0.2); /* Light button shadow */
}

.nav-button:hover {
    background-color: #0056b3; /* Darker blue on hover */
    transform: translateY(-2px); /* Slight lift on hover */
    box-shadow: 0 8px 15px rgba(0, 123, 255, 0.3); /* Enhanced shadow on hover */
}

.nav-button:active {
    background-color: #004494; /* Even darker blue on click */
    transform: translateY(1px); /* Button pushed down on click */
}

/* Sidebar Styles */
.sidebar {
    display: none; /* Hide sidebar by default */
    position: fixed;
    top: 0;
    right: 0;
    width: 250px;
    height: 100%;
    background: linear-gradient(135deg, #aed7ff, #dbeef6); /* Matching the header gradient */
    box-shadow: -4px 0 15px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
    transform: translateX(100%);
    z-index: 999;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; /* Center content vertically */
    padding-top: 60px;
}

.sidebar.open {
    display: flex;
    transform: translateX(0); /* Slide sidebar into view */
}

.sidebar ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.sidebar ul li {
    margin: 20px 0;
    text-align: center;
}

.sidebar ul li a {
    color: #007bff;
    text-decoration: none;
    font-size: 20px;
    font-weight: bold;
    transition: color 0.3s;
}

.sidebar ul li a:hover {
    color: #ff6b6b;
}

/* Hamburger Button */
.hamburger {
    display: none;
    background: none;
    border: none;
    color: #000000;
    font-size: 30px;
    cursor: pointer;
    outline: none;
}

/* Sidebar Overlay */
.sidebar-overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 998;
    opacity: 0; /* Start as invisible */
    transition: opacity 0.3s ease-in-out;
}

.sidebar-overlay.visible {
    display: block;
    opacity: 1;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
    .nav-links {
        display: none; /* Hide the regular nav links on mobile */
    }

    .hamburger {
        display: block; /* Show hamburger button on mobile */
    }

    .sidebar-overlay {
        display: block; /* Enable overlay in mobile */
    }
}
