.content-sections {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

.section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 40px;
    margin: 20px 0;
    border-radius: 15px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
}

.section:hover {
    transform: translateY(-10px); /* Cool hover effect */
}

.section img {
    width: 45%;
    height: auto;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.section .content {
    width: 45%;
}

.section .content h3 {
    margin: 0 0 20px 0;
    font-size: 28px;
    color: #333;
}

.section .content p {
    font-size: 18px;
    color: #555;
}

.section1 {
    background: linear-gradient(135deg, #a6c1ee, #fbc2eb); /* Warm gradient */
    color: #fff;
}

.section2 {
    background: linear-gradient(135deg, #a18cd1, #fbc2eb); /* Purple to pink gradient */
    color: #fff;
    flex-direction: row-reverse; /* Image on the right, content on the left */
}

.section3 {
    background: linear-gradient(135deg, #fbc2eb, #a6c1ee); /* Pink to blue gradient */
    color: #fff;
}
