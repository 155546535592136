.testimonials {
    padding: 60px 20px; /* Increased padding for more space */
    background-color: #f9f9f9; /* Slightly lighter background for contrast */
    text-align: center;
    position: relative; /* For positioning effects */
    overflow: hidden; /* Contain any overflow */
}

.testimonials h2 {
    font-size: 36px;
    color: #333;
    margin-bottom: 40px; /* Increased margin for spacing */
    animation: fadeInDown 1s ease-out; /* Heading animation */
}

.testimonial {
    padding: 30px;
    max-width: 800px; /* Slightly wider for larger screens */
    margin: 0 auto;
    background-color: #fff; /* Background color for each testimonial */
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth hover effects */
}

.testimonial:hover {
    transform: translateY(-10px); /* Lift on hover */
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover */
}

.testimonial .quote {
    font-size: 22px;
    color: #555;
    font-style: italic;
    margin-bottom: 20px;
    animation: fadeInUp 1.2s ease-out; /* Quote animation */
}

.testimonial .client-name {
    font-size: 20px;
    font-weight: bold;
    color: #007bff;
    animation: fadeInUp 1.4s ease-out; /* Name animation */
}

.testimonial .client-company {
    font-size: 18px;
    color: #777;
    animation: fadeInUp 1.6s ease-out; /* Company animation */
}

/* Animations */
@keyframes fadeInDown {
    0% {
        opacity: 0;
        transform: translateY(-20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Mobile Optimization */
@media (max-width: 768px) {
    .testimonials {
        padding: 40px 10px; /* Adjust padding on mobile */
    }

    .testimonial {
        padding: 20px; /* Reduce padding inside testimonials */
    }

    .testimonial .quote {
        font-size: 18px; /* Adjust font size on mobile */
    }

    .testimonial .client-name {
        font-size: 18px; /* Adjust font size on mobile */
    }

    .testimonial .client-company {
        font-size: 16px; /* Adjust font size on mobile */
    }

    .slick-dots {
        bottom: -30px; /* Adjust dots position */
    }

    .slick-prev, .slick-next {
        display: none !important; /* Hide arrows on mobile for simplicity */
    }
}
