.services {
    padding: 40px 20px;
    background-color: #f4f4f4;
    text-align: center;
}

.services h2 {
    font-size: 36px;
    color: #333;
    margin-bottom: 40px;
}

.service-category {
    margin-bottom: 50px;
}

.service-category h3 {
    font-size: 28px;
    color: #007bff;
    margin-bottom: 20px;
}

.package-cards {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 20px;
}

/* Mobile Optimization */
@media (max-width: 768px) {
    .services {
        padding: 20px 10px;
    }

    .services h2 {
        font-size: 28px;
        margin-bottom: 30px;
    }

    .service-category h3 {
        font-size: 24px;
        margin-bottom: 15px;
    }

    .package-cards {
        flex-direction: column;
        align-items: center;
    }

    .package-card {
        width: 100%;
        max-width: 90%;
        margin-bottom: 20px;
    }
}
