body {
  background-color: #fdfdfd;
  color: #333;
}

h1, h2, h3, h4, h5, h6 {
  color: #444;
}

a {
  color: #007bff;
}

a:hover {
  color: #0056b3;
}

.container {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
