.tagline-section {
    display: flex;
    align-items: center;
    padding: 40px;
    background-color: #f9f9f9;
    flex-wrap: wrap; /* Allows wrapping of elements */
}

.tagline-content {
    flex: 1;
    padding: 20px;
}

.tagline-content h2 {
    font-size: 36px;
    margin-bottom: 20px;
    color: #333;
}

.tagline-content p {
    font-size: 18px;
    margin-bottom: 30px;
    color: #666;
}

.quote-button {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.quote-button:hover {
    background-color: #0056b3;
    color: #fff;
}

.tagline-image {
    flex: 1;
    display: flex;
    justify-content: center; /* Center the image horizontally */
    align-items: center; /* Center the image vertically */
}

.tagline-image img {
    width: 100%;
    max-width: 500px; /* Limit the max width on larger screens */
    border-radius: 8px;
}

/* Mobile Optimization */
@media (max-width: 768px) {
    .tagline-section {
        flex-direction: column; /* Stack content and image vertically */
        padding: 20px; /* Reduce padding on mobile */
    }

    .tagline-content {
        text-align: center; /* Center text on mobile */
        padding: 10px 0; /* Reduce padding */
    }

    .tagline-content h2 {
        font-size: 28px; /* Slightly smaller font size on mobile */
    }

    .tagline-content p {
        font-size: 16px; /* Slightly smaller font size on mobile */
        margin-bottom: 20px; /* Adjust margin */
    }

    .quote-button {
        width: 100%; /* Make the button full width on mobile */
        padding: 12px; /* Increase padding for touch targets */
    }

    .tagline-image {
        margin-top: 20px; /* Add space between content and image */
    }

    .tagline-image img {
        width: 80%; /* Reduce image size on mobile */
        max-width: 100%; /* Ensure it doesn't overflow */
    }
}
