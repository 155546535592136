.packages {
    padding: 40px 0;
    background-color: #f4f4f4;
    text-align: center;
}

.packages h2 {
    font-size: 32px;
    color: #333;
    margin-bottom: 20px;
}

.package-cards {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 20px;
}
