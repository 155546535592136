.package-card {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center; /* Center align content */
    width: 100%;
    max-width: 300px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    margin: 0 auto; /* Center the card in its container */
}

.package-card:hover {
    transform: translateY(-10px); /* Lift the card on hover */
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15); /* Enhance shadow on hover */
}

.package-card h3 {
    font-size: 24px;
    color: #007bff;
    margin-bottom: 10px;
    animation: fadeInDown 0.5s ease-out;
}

.package-card p {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 15px;
    color: #444;
    animation: fadeInUp 0.5s ease-out;
}

.package-card ul {
    margin-bottom: 20px;
    padding-left: 0; /* Remove default padding */
    list-style-type: none; /* Remove default bullets */
    animation: fadeInUp 0.7s ease-out;
}

.package-card ul li {
    font-size: 16px;
    color: #666;
    margin-bottom: 10px;
    position: relative;
    padding-left: 20px; /* Adjust padding for custom bullet */
}

.package-card ul li::before {
    content: "✔";
    color: #007bff;
    margin-right: 10px;
    position: absolute;
    left: 0;
    top: 0;
}

.contact-button {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
    animation: fadeInUp 0.9s ease-out;
    margin-top: 15px;
    text-decoration: none; /* Remove underline from link */

}

.contact-button:hover {
    background-color: #0056b3;
    color: #fff;
    transform: translateY(-2px); /* Slight lift on hover */
}

.contact-button:active {
    transform: translateY(1px); /* Button press effect */
}
/* Animations */
@keyframes fadeInDown {
    0% {
        opacity: 0;
        transform: translateY(-20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Mobile Optimization */
@media (max-width: 768px) {
    .package-cards {
        flex-direction: column;
        align-items: center;
    }

    .package-card {
        width: 90%; /* Make cards responsive on mobile */
        margin-bottom: 20px; /* Add space between cards */
    }
}
